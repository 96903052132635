import { ViewBasic } from '@quatrecentquatre/manage-me';

export class VideoPlayer extends ViewBasic {
    constructor(options) {
        super(options);
        this.bindAll([
            'pauseVideo',
            'stopVideo',
            'playVideo',
            'toggleVideo',
            'handleVideoPlaying',
            'dispatchVideoPlaying',
        ]);
        // this.setVideo();
        this.isPlaying = false;
    }

    initialize() {
        super.initialize();
    }

    addEvents() {
        super.addEvents();

        if(this.el.querySelector('.video-poster')){
            this.el.querySelector('.video-poster').addEventListener('click', this.toggleVideo);
        }

        window.addEventListener('VIDEO_PLAYING', this.handleVideoPlaying);
    }

    removeEvents() {
        super.removeEvents();

        if(this.el.querySelector('.video-poster')) {
            this.el.querySelector('.video-poster').removeEventListener('click', this.toggleVideo);
        }
        window.removeEventListener('VIDEO_PLAYING', this.handleVideoPlaying);
    }

    /**
     * Switches between playing and pausing the video based on its current state.
     */

    toggleVideo() {
        if (this.isPlaying) {
            this.pauseVideo();
        } else {
            this.playVideo();
        }
    }

    /**
     * Pauses the current video player if another video player starts playing.
     * @param e
     */

    handleVideoPlaying(e) {
        let mediaElement = this.el.closest('.media');
        if (!mediaElement) return; // Exit if the media element is not found

        // If the event detail does not match this player's identifier, pause this player

        if (e.detail && e.detail.id !== mediaElement.getAttribute('data-video-id') && !mediaElement.dataset.autoplay) {
            this.pauseVideo();
        }
    }

    /**
     * Dispatches a custom event signaling that the current video has started playing, providing its identifier.
     */

    dispatchVideoPlaying() {
        let customEvent = new CustomEvent('VIDEO_PLAYING', {
            detail: {
                id: this.el.closest('.media').getAttribute('data-video-id'),
            },
        });
        window.dispatchEvent(customEvent);
    }

    /**
     * Triggered when videoplayer is ready to be interacted with.
     * autoplay property is set to true whenever we want to open the overlay on a specific media and avoid the user to press play again.
     */
    onVideoPlayerReady() {
        if (this.autoplay) {
            this.playVideo();
        }
    }

    /**
     * Play video players. to be overwritten in parent class
     */
    playVideo() {
        if(this.el.querySelector('.video-poster')) {
            this.el.querySelector('.video-poster').classList.add('inactive');
        }
    }

    /**
     * Stop video players. to be overwritten in parent class
     */
    stopVideo() {
        this.removeEvents();
        if(this.el.querySelector('.video-poster')) {
            this.el.querySelector('.video-poster').classList.remove('inactive');
        }
    }

    /**
     * Pause video players. to be overwritten in parent class
     */
    pauseVideo() {
        if(this.el.querySelector('.video-poster')) {
            this.el.querySelector('.video-poster').classList.remove('inactive');
        }
    }

    /**
     * Prep video players. to be overwritten in parent class
     */
    setVideo() {}
}

Me.views['VideoPlayer'] = VideoPlayer;
