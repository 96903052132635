import { VideoPlayer } from '../../classes/video-player';

export class VimeoPlayer extends VideoPlayer {
    constructor(options) {
        super(options);

        this.bindAll(['setVideo', 'playVideo', 'stopVideo', 'pauseVideo']);

        this.autoplay = this.el.dataset.autoplay;

        if(!document.getElementById('vimeo-player-script')){
            let vimeoScript = document.createElement('script');
            vimeoScript.id = 'vimeo-player-script';
            vimeoScript.src = "https://player.vimeo.com/api/player.js";

            // Dispatch event when Vimeo API is loaded. This sets the video in the player.
            vimeoScript.addEventListener('load', function() {
                document.dispatchEvent(new Event('vimeoVideoReady'));
            });

            let scriptTag = document.getElementsByTagName('script')[1];
            scriptTag.parentNode.insertBefore(vimeoScript, scriptTag);
        }
    }

    addEvents() {
        super.addEvents();
        document.addEventListener('vimeoVideoReady', this.setVideo);
    }

    removeEvents() {
        super.removeEvents();
        document.removeEventListener('vimeoVideoReady', this.setVideo);
    }

    /**
     * Play video player and hide the poster
     */
    playVideo() {
        super.playVideo();
        if (this.videoPlayer) {
            this.isPlaying = true;
            this.videoPlayer.play();
            if(!this.autoplay) {
                this.dispatchVideoPlaying();
            }
        }
    }

    /**
     * Stop video player and show the poster
     */
    stopVideo() {
        super.stopVideo();
        if (this.videoPlayer) {
            this.isPlaying = false;
            this.videoPlayer.pause();
        }
    }

    /**
     * Pause video player and show the poster when another video starts
     */
    pauseVideo() {
        super.pauseVideo();
        if (this.videoPlayer) {
            if (this.isPlaying) {
                this.isPlaying = false;
                this.videoPlayer.pause();
            }
        }
    }

    /**
     * Prepare video player
     */
    setVideo() {
        this.playerReady = true;
        let scope = this;

        let videoID = this.el.getAttribute('data-video-id');

        if (videoID) {
            this.videoPlayer = new Vimeo.Player(this.el.querySelector('.video-player'), {
                url: 'https://vimeo.com/' + videoID,
                autoplay: !!this.el.dataset.autoplay,
                autopause: false,
                controls: !this.el.dataset.autoplay,
                muted: !!this.el.dataset.autoplay,
                loop: !!this.el.dataset.autoplay,
            });

            // Unset pointer events on video poster so that we can play/pause video since it has no controls
            if(this.el.dataset.autoplay){
                document.querySelector('.video-poster').style.pointerEvents = 'unset';
            }
            //load and play video if requested in OnVideoPlayerReady.
            this.videoPlayer.ready()
                .then(function (id) {
                    scope.onVideoPlayerReady();
                })
                .catch(function (error) {
                    console.error('Error loading Vimeo video:', error);
                });
        }
    }
}

Me.views['VimeoPlayer'] = VimeoPlayer;
