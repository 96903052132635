import { Overlay } from './overlay';

export class CheckCompatibilityOverlay extends Overlay {
    constructor(options) {
        super(options);
    }

    initialize() {
        this.bindAll(['changeRestaurant', 'handleBrandChange', 'handleCompatibilitySubmit', 'handleBackButtonClick']);

        super.initialize();
    }

    addEvents() {
        super.addEvents();

        this.el.querySelector('#compatibility-brand-select').addEventListener('change', this.handleBrandChange);
        this.el.querySelector('#compatibility-check-submit').addEventListener('click', this.handleCompatibilitySubmit);
        this.el.querySelector('.back-btn').addEventListener('click', this.handleBackButtonClick);
    }

    removeEvents() {
        super.removeEvents();
    }

    handleBrandChange() {
        const form = this.el.querySelector('#compatibility-brand-select').parentElement;
        const formData = new FormData(form);
        const select = this.el.querySelector('#compatibility-model-select');

        select.classList.add('loading');

        fetch(form.action, {
            method: 'POST',
            body: formData,
        })
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                if (json.models) {
                    // Clear all models except default option
                    select.querySelectorAll('option:not(:first-child)').forEach((option) => option.remove());

                    // Append the models
                    json.models.forEach((model) => {
                        select.appendChild(new Option(model.models_compatibility_title, model.uid));
                    });
                }

                select.classList.remove('loading');
            });
    }

    handleCompatibilitySubmit() {
        const form = this.el.querySelector('#compatibility-model-select').parentElement;

        if (!form.checkValidity()) {
            form.reportValidity();
            return false;
        }

        const formData = new FormData(form);
        fetch('/ajax/product-compatibility/compatible', {
            method: 'POST',
            body: formData,
        })
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                this.el.querySelector('.compatibility-check-form-wrapper').classList.add('hide');
                this.el.querySelector('.compatibility-check-results-wrapper').classList.remove('hide');
                this.el.querySelector('.back-btn').classList.remove('hide');
                this.el.querySelector('#compatibility-form-lead').classList.remove('hide');
                this.el.querySelector('.form-success-message').classList.add('hide');
                this.el.querySelector('.form-error-message').classList.add('hide');

                const brandSelect = this.el.querySelector('#compatibility-brand-select');
                const brandName = brandSelect.options[brandSelect.selectedIndex].text;
                const modelSelect = this.el.querySelector('#compatibility-model-select');
                const modelName = modelSelect.options[modelSelect.selectedIndex].text;

                // Add brand name to description
                this.el.querySelectorAll('.brand-name').forEach((span) => {
                    span.innerText = brandName;
                });
                this.el.querySelectorAll('.model-name').forEach((span) => {
                    span.innerText = modelName;
                });

                // Fill in data for final lead form
                const leadForm = this.el.querySelector('#compatibility-form-lead');
                leadForm.querySelector('input[name="fields[form_brand]"]').value = brandSelect.value;
                leadForm.querySelector('input[name="fields[form_model]"]').value = modelSelect.value;
                leadForm.querySelector('input[name="fields[form_is_compatible]"]').value = json.success ? 1 : 0;

                if (json.success) {
                    this.el.querySelector('.compatible').classList.remove('hide');
                    this.el.querySelector('.not-compatible').classList.add('hide');
                } else {
                    this.el.querySelector('.not-compatible').classList.remove('hide');
                    this.el.querySelector('.compatible').classList.add('hide');
                }
            });
    }

    handleBackButtonClick() {
        this.el.querySelector('.compatibility-check-form-wrapper').classList.remove('hide');
        this.el.querySelector('.compatibility-check-results-wrapper').classList.add('hide');
        this.el.querySelector('.back-btn').classList.add('hide');
    }
}

Me.views['CheckCompatibilityOverlay'] = CheckCompatibilityOverlay;
