import { ViewBasic } from '@quatrecentquatre/manage-me';
import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';

export class HeroHomepage extends ViewBasic {
    constructor(options) {
        super(options);

        this.imageElements = null;
        this.imageElementsMobile = null;
        this.progressElement = null;
        this.progressItems = null;
        this.activeIndex = 0;
        gsap.registerPlugin(CustomEase);
    }

    initialize() {
        this.bindAll(['initSlideshow']);

        this.imageElements = this.el.querySelectorAll('.image-frame.desktop');
        this.imageElementsMobile = this.el.querySelectorAll('.image-frame.mobile');
        this.progressElement = this.el.querySelector('progress');
        this.progressItems = this.el.querySelectorAll('.items span');

        if (this.progressItems.length) {
            this.initSlideshow();
        }

        this.addEvents();
    }

    addEvents() {
        super.addEvents();

        window.addEventListener('breakpointChanged', this.initSlideshow);
    }

    initSlideshow() {
        const scope = this;

        this.hideAllImages();
        this.imageElements[0].classList.remove('image-hidden');
        this.imageElementsMobile[0].classList.remove('image-hidden');

        //reset slideshow on important change (when initslidshow is recalled)
        if(this.slideshow){
            this.slideshow.restart();
            this.slideshow.kill();
        }

        //need a special easing to fill rapidly on desktop the start  because there is a big empty gap on the left side before texts

        if(window.innerWidth <= 1024){
            //mobile & tablet
            this.slideshow = gsap.to(this.progressElement, {
                value: 100,
                duration: 3 * (this.progressItems.length + 1),
                ease: 'linear',
                repeat: -1,
                onUpdate: function () {
                    // Calculate the current index based on the % progression
                    let index = Math.floor((this.progress() * 100) / (100 / scope.progressItems.length));
                    if (index === scope.progressItems.length) {
                        index = scope.progressItems.length - 1;
                    }

                    // New index, we show the next image
                    if (index !== scope.activeIndex) {
                        scope.activeIndex = index;

                        scope.hideAllImages();
                        scope.imageElements[index].classList.remove('image-hidden');
                        scope.imageElementsMobile[index].classList.remove('image-hidden');
                    }
                }
            });
        }else{
            //desktop
            this.slideshow = gsap.to(this.progressElement, {
                value: 100,
                duration: 3 * (this.progressItems.length + 1),
                ease: CustomEase.create("custom", "M0,0 C0,0.315 0.263,0.397 0.474,0.546 0.634,0.659 0.657,0.7 1,1 "),
                repeat: -1,
                onUpdate: function () {
                    if(scope.progressItems[0].getBoundingClientRect().left > scope.el.querySelector('progress').offsetWidth * (this.ratio)){
                        scope.activeIndex = 0;

                        scope.hideAllImages();
                        scope.imageElements[scope.activeIndex].classList.remove('image-hidden');
                        scope.imageElementsMobile[scope.activeIndex].classList.remove('image-hidden');
                    }else if(scope.activeIndex != scope.progressItems.length - 1){
                        if (scope.progressItems[scope.activeIndex+1].getBoundingClientRect().left < scope.el.querySelector('progress').offsetWidth * (this.ratio)) {
                            scope.activeIndex += 1;

                            scope.hideAllImages();
                            scope.imageElements[scope.activeIndex].classList.remove('image-hidden');
                            scope.imageElementsMobile[scope.activeIndex].classList.remove('image-hidden');
                        }
                    }
                }
            });
        }
    }

    hideAllImages() {
        this.imageElements.forEach((imageElement) => {
            imageElement.classList.add('image-hidden');
        });

        this.imageElementsMobile.forEach((imageElement) => {
            imageElement.classList.add('image-hidden');
        });
    }
}

Me.views['HeroHomepage'] = HeroHomepage;
