import { ViewBasic } from '@quatrecentquatre/manage-me';

export class ProductImages extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        this.bindAll(['changeImage']);

        this.mainImage = this.el.querySelector('.main-image img');
        this.thumbnails = this.el.querySelectorAll('.thumbnails .thumb');

        this.addEvents();
    }

    addEvents() {
        if (this.thumbnails.length) {
            this.thumbnails.forEach((thumbnail) => {
                thumbnail.addEventListener('click', this.changeImage);
            });
        }
    }

    removeEvents() {}

    changeImage(event) {
        const thumbSelected = event.target.closest('.thumb');

        this.mainImage.onload = () => {
            if (thumbSelected.dataset.index !== '1') {
                this.mainImage.classList.add('js-changed');
            } else this.mainImage.classList.remove('js-changed');
        };
        this.mainImage.srcset = '';
        this.mainImage.src = thumbSelected.dataset.imageFullsize;

        if (this.thumbnails.length) {
            this.thumbnails.forEach((thumbnail, index) => {
                if (thumbSelected === thumbnail) {
                    thumbnail.classList.add('active');
                } else thumbnail.classList.remove('active');
            });
        }
    }
}

Me.views['ProductImages'] = ProductImages;
