import { ViewBasic } from '@quatrecentquatre/manage-me';
import Swiper from 'swiper';
import { Thumbs, Navigation, Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';

export class ImagesSlideshow extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        const thumbsSwiper = new Swiper(this.el.querySelector('.swiper.thumbnails'), {
            loop: true,
            spaceBetween: 11,
            slidesPerView: 4,
            watchSlidesProgress: true,
            breakpoints: {
                768: {
                    slidesPerView: 'auto',
                },
            },
        });

        const swiper = new Swiper(this.el.querySelector('.swiper.main-image-slideshow'), {
            modules: [Thumbs, Navigation, Autoplay, EffectFade],
            loop: true,
            slidesPerView: 1,
            navigation: {
                nextEl: this.el.querySelector('.navigation-next'),
            },
            thumbs: {
                swiper: thumbsSwiper,
                multipleActiveThumbs: false,
            },
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            },
        });
    }

    addEvents() {}

    removeEvents() {}
}

Me.views['ImagesSlideshow'] = ImagesSlideshow;
