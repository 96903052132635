import { ViewBasic } from '@quatrecentquatre/manage-me';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

class Main extends ViewBasic {
    constructor(options) {
        super(options);
        this.name = 'Main';

        this.initialize();
    }

    initialize() {
        this.bindAll(['resize', 'initAnimations']);

        //this class is applying css transition duration of 0 to prevent animating from resetting when refreshing the page.
        document.querySelector('body').classList.remove('preload');

        this.breakpoints = [
            window.SETTINGS.BREAKPOINTS.SM_MIN,
            window.SETTINGS.BREAKPOINTS.MD_MIN,
            window.SETTINGS.BREAKPOINTS.LG_MIN,
        ];

        this.addEvents();

        this.initAnimations();
    }

    addEvents() {
        window.addEventListener('resize', this.resize);
    }

    removeEvents() {
        window.removeEventListener('resize', this.resize);
    }

    initAnimations() {
        // Init parallax elements
        const parallaxBgs = gsap.utils.toArray('.parallax-img');
        if (parallaxBgs.length != 0) {
            let multiplier = window.innerWidth < 1024 ? 20 : 100;
            parallaxBgs.forEach((parallaxBg) => {
                gsap.to(parallaxBg, {
                    scrollTrigger: {
                        trigger: parallaxBg,
                        scrub: true,
                    },
                    y: (i, target) => parseFloat(target.dataset.speed) * multiplier,
                    ease: 'none',
                });
            });
        }

        // Init fade-in animations
        const fadeIns = gsap.utils.toArray('.fade-in');
        if (fadeIns.length != 0) {
            fadeIns.forEach((fadeIn) => {
                gsap.fromTo(
                    fadeIn,
                    {
                        opacity: 0,
                        y: 30,
                    },
                    {
                        opacity: 1,
                        y: 0,
                        scrollTrigger: {
                            trigger: fadeIn,
                            start: 'top bottom',
                            end: 'top 60%',
                        },
                        ease: 'power3.inOut',
                        duration: 0.9,
                    },
                );
            });
        }

        // Init lists animations
        const scrollAnimListItems = gsap.utils.toArray('.scroll-anim-list-item');
        if (scrollAnimListItems.length != 0) {
            scrollAnimListItems.forEach((scrollAnimListItem) => {
                let scrollTriggerOptions = {
                    trigger: scrollAnimListItem,
                    start: 'top bottom',
                    end: 'top 60%',
                    scrub: 0.5,
                };

                gsap.fromTo(
                    scrollAnimListItem,
                    { y: 100 },
                    {
                        y: 0,
                        scrollTrigger: scrollTriggerOptions,
                    },
                );

                const separator = scrollAnimListItem.querySelector('.scroll-anim-item-separator');
                if (separator) {
                    gsap.fromTo(
                        separator,
                        { y: 100 },
                        {
                            y: 0,
                            scrollTrigger: scrollTriggerOptions,
                        },
                    );
                }
            });
        }
    }

    resize(e) {
        let breakpoint;
        const viewPortSize = this.GET_OUTER_WIDTH(document.querySelector('html'));

        if (viewPortSize <= window.SETTINGS.BREAKPOINTS.XS_MAX) {
            window.isMobile = true;
        }
        if (viewPortSize <= window.SETTINGS.BREAKPOINTS.SM_MAX) {
            window.isTablet = true;
        } else {
            window.isTablet = false;
            window.isMobile = false;
        }

        if (viewPortSize < this.breakpoints[0]) {
            breakpoint = String(this.breakpoints[0] - 1) + '-';
        }

        for (let i in this.breakpoints) {
            if (
                this.breakpoints[Number(i) + 1] != undefined &&
                viewPortSize >= this.breakpoints[i] &&
                viewPortSize < this.breakpoints[Number(i) + 1]
            ) {
                breakpoint = String(this.breakpoints[i]) + '-' + String(this.breakpoints[Number(i) + 1] - 1);
            }
            if (viewPortSize >= this.breakpoints[this.breakpoints.length - 1]) {
                breakpoint = String(this.breakpoints[i]) + '+';
            }
        }

        if (window.SETTINGS.BREAKPOINT != breakpoint) {
            window.SETTINGS.BREAKPOINT = breakpoint;

            let eventDispatch = new CustomEvent('breakpointChanged', {
                bubbles: true,
                detail: {
                    breakpoint: breakpoint,
                },
            });

            //dispatch breakpointChanged
            window.dispatchEvent(eventDispatch);
        }

        window.dispatchEvent(new Event('window.resize'));
    }

    GET_OUTER_WIDTH(element) {
        let width = element.offsetWidth;
        let style = getComputedStyle(element);

        width += parseInt(style.marginLeft) + parseInt(style.marginRight);
        return width;
    }
}

gsap.registerPlugin(ScrollTrigger);

document.addEventListener('DOMContentLoaded', function () {
    new Main({});
});
