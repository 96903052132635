import { ViewBasic } from '@quatrecentquatre/manage-me';

export class LanguageSwitcher extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        this.bindAll(['toggleDropdown']);
        this.addEvents();
    }

    addEvents() {
        this.el
            .querySelector('.language-switcher-btn')
            .addEventListener('click', this.toggleDropdown);
    }

    removeEvents() {}

    toggleDropdown() {
        this.el.classList.toggle('is-opened');
    }
}

Me.views['LanguageSwitcher'] = LanguageSwitcher;
