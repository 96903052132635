import { VideoPlayer } from '../../classes/video-player';

export class Html5Player extends VideoPlayer {
    constructor(options) {
        super(options);

        this.bindAll(['toggleVideo']);

        this.videoElement = this.el.querySelector('video');
        this.autoplay = this.el.dataset.autoplay;
    }

    addEvents() {
        super.addEvents();

        this.videoElement.addEventListener('play', () => {
            this.playVideo();
        });
        this.videoElement.addEventListener('pause', () => {
            this.pauseVideo();
        });
    }

    playVideo() {
        super.playVideo();
        if (this.videoElement) {
            this.isPlaying = true;
            this.videoElement.play();
            this.dispatchVideoPlaying();
        }
    }

    pauseVideo() {
        super.pauseVideo();
        if (this.videoElement) {
            this.isPlaying = false;
            this.videoElement.pause();
        }
    }

    /**
     * Stop video player and show the poster
     */
    stopVideo() {
        super.stopVideo();
        if (this.videoElement) {
            this.isPlaying = false;
            this.videoElement.pause();
        }
    }
}

Me.views['Html5Player'] = Html5Player;
