import { ViewBasic } from '@quatrecentquatre/manage-me';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';

export class ProductsSlider extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        const swiper = new Swiper(this.el.querySelector('.swiper'), {
            modules: [Navigation],
            slidesPerView: 'auto',
            navigation: {
                prevEl: this.el.querySelector('.navigation .previous'),
                nextEl: this.el.querySelector('.navigation .next'),
            },
        });
    }

    addEvents() {}

    removeEvents() {}
}

Me.views['ProductsSlider'] = ProductsSlider;
