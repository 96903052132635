import '../scss/app.scss';

import './app.config';

//NODE_MODULES
import '@quatrecentquatre/manage-me';

function importAll(r) {
    r.keys().forEach(r);
}

//VIEWS
// automatic import
importAll(require.context('./views/', true, /\.js$/));

// or listed manually
// import './views/exemple';

//MAIN SCRIPTS
import './main';

// Only used in dev
if (module.hot) {
    module.hot.accept();
}
