import { string } from 'yup';
import { FormBase } from '@quatrecentquatre/form-me';
import { mixed } from 'yup';

export class ContactForm extends FormBase {
    constructor(options) {
        super(options);
        //set default variables

        this.formWrapper = this.$el.querySelector('.form-wrapper');
        this.fileLink = this.$el.querySelector('#contact-file-link');
        this.reasonSelect = this.$el.querySelector('#contact-reason-select');

        // File input validation is added or removed dynamically based on the reason (see handleReasonChange())

        // Reason selection can be not included in the page (Contact Salesmen page)
        if (this.reasonSelect) {
            this.addField({
                name: 'fields[form_reason]',
                validationSchema: string().required(),
                $error: this.reasonSelect.parentElement.querySelector('.error-msg'),
            });
        }

        this.addFields([
            {
                name: 'fields[form_firstname]',
                validationSchema: string().required(),
                $error: this.$el
                    .querySelector('[name="fields[form_firstname]"]')
                    .parentElement.querySelector('.error-msg'),
            },
            {
                name: 'fields[form_lastname]',
                validationSchema: string().required(),
                $error: this.$el
                    .querySelector('[name="fields[form_lastname]"]')
                    .parentElement.querySelector('.error-msg'),
            },
            {
                name: 'fields[form_email]',
                validationSchema: string().email().required(),
                $error: this.$el.querySelector('[name="fields[form_email]"]').parentElement.querySelector('.error-msg'),
            },
            {
                name: 'fields[form_message]',
                validationSchema: string().required(),
                $error: this.$el
                    .querySelector('[name="fields[form_message]"]')
                    .parentElement.querySelector('.error-msg'),
            },
            {
                name: 'data_acceptation',
                validationSchema: string().required(),
                $error: this.$el
                    .querySelector('[name="data_acceptation"]')
                    .parentElement.parentElement.querySelector('.error-msg'),
            },
            {
                name: 'terms_acceptation',
                validationSchema: string().required(),
                $error: this.$el
                    .querySelector('[name="terms_acceptation"]')
                    .parentElement.parentElement.querySelector('.error-msg'),
            },
        ]);
    }

    initialize() {
        super.initialize();
        this.button = this.$el.querySelector('button[type="submit"]');

        // Reason selection can be not included in the page (Contact Salesmen page)
        if (this.reasonSelect) {
            this.handleReasonChange = this.handleReasonChange.bind(this);
            this.reasonSelect.addEventListener('change', this.handleReasonChange);

            const urlParams = new URLSearchParams(window.location.search);
            const scope = this;

            if (urlParams.get('q')) {
                scope.reasonSelect.value = scope.reasonSelect.querySelector(
                    `[data-slug="${urlParams.get('q')}"]`,
                ).value;
                scope.reasonSelect.querySelector(`[data-slug="${urlParams.get('q')}"]`).selected = 'selected';
                scope.handleReasonChange();
            }
        }
    }

    handleReasonChange() {
        const MAX_FILE_SIZE = 10000000;
        const supportedFormats = ['pdf'];

        if (this.reasonSelect.selectedOptions && this.reasonSelect.selectedOptions[0]) {
            if (
                this.reasonSelect.selectedOptions[0].value !== '' &&
                this.reasonSelect.selectedOptions[0].dataset.fileurl &&
                this.reasonSelect.selectedOptions[0].dataset.filename
            ) {
                // This option has a file to download
                this.fileLink.href = this.reasonSelect.selectedOptions[0].dataset.fileurl;
                this.fileLink.querySelector('.label').innerText = this.reasonSelect.selectedOptions[0].dataset.filename;

                // Show extra steps
                this.$el.querySelector('.step-1').classList.remove('hide');
                this.$el.querySelector('.step-2').classList.remove('hide');
                this.$el.querySelector('.step-3 .step-intro').classList.remove('hide');

                // Add validation for file input
                this.$el.querySelector('[name="fields[form_files][]"]').required = true;
                this.addField({
                    name: 'fields[form_files][]',
                    validationSchema: mixed()
                        .test('is-valid-size', function (value) {
                            if (!value || !value.name) return false;
                            return value.size <= MAX_FILE_SIZE;
                        })
                        .test('is-valid-format', function (value) {
                            if (!value || !value.name) return false;
                            return supportedFormats.includes(value.name.split('.').pop());
                        })
                        .required(),
                    $error: this.$el
                        .querySelector('[name="fields[form_files][]"]')
                        .parentElement.parentElement.parentElement.querySelector('.error-msg'),
                    $targetErrorClass: this.$el.querySelector('.file-input'),
                });

                this.formWrapper.classList.add('is-visible');
                this.$el.querySelector('.success-message').classList?.add('hide');
                this.$el.querySelector('.error-message').classList?.add('hide');
            } else if (this.reasonSelect.selectedOptions[0].value !== '') {
                // No file, hide extra steps
                this.$el.querySelector('.step-1').classList.add('hide');
                this.$el.querySelector('.step-2').classList.add('hide');
                this.$el.querySelector('.step-3 .step-intro').classList.add('hide');

                // Remove validation for file input
                this.$el.querySelector('[name="fields[form_files][]"]').required = false;
                if (this.getField('fields[form_files][]')) this.removeField('fields[form_files][]');

                this.formWrapper.classList.add('is-visible');
                this.$el.querySelector('.success-message').classList?.add('hide');
                this.$el.querySelector('.error-message').classList?.add('hide');
            } else {
                this.formWrapper.classList.remove('is-visible');
            }
        } else this.formWrapper.classList.remove('is-visible');
    }

    /**
     * Scroll to error position. Query selector finds first error.
     */

    handleValidationError() {
        super.handleValidationError();

        // Scroll to position of error.
        const targetElement = this.$el.querySelector('.has-error');
        const { top } = targetElement.getBoundingClientRect();
        const targetOffset = window.scrollY + top - 200;

        window.scroll({
            top: targetOffset,
            behavior: 'smooth',
        });
    }

    handleAjaxSend(e) {
        this.button.disabled = true;
        this.addLoader();

        super.handleAjaxSend(e);
    }

    ajaxError(error) {
        this.$el.querySelector('.success-message').classList.add('hide');
        this.$el.classList.add(this.classes.serverError);
        this.$el.querySelector('.error-message').innerText = this.$el
            .querySelector('.error-message')
            .getAttribute('data-default');
        this.$el.querySelector('.error-message').classList?.remove('hide');
    }

    ajaxSuccess(response) {
        if (response.success) {
            // Push to GTM dataLayer
            let reason = 'Bonus Salesmen';
            if (this.reasonSelect) {
                reason = this.reasonSelect.selectedOptions[0].innerText;
            }
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'contact-form-submit',
                reason,
            });

            // Reset form
            this.$el.reset();

            if (this.reasonSelect) {
                this.handleReasonChange();
            }

            // Remove has-value classes from all form fields
            let hasValue = this.$el.querySelectorAll('.has-value');
            if (hasValue) {
                hasValue.forEach((entry) => {
                    entry.classList.remove('has-value');
                });
            }

            // Show success message, hide error if visible
            this.$el.querySelector('.success-message').classList?.remove('hide');
            this.$el.querySelector('.error-message').classList?.add('hide');
        } else {
            // Show error message, hide success if visible
            this.$el.querySelector('.success-message').classList?.add('hide');
            this.$el.querySelector('.error-message').innerText = response.errors[0];
            this.$el.querySelector('.error-message').classList?.remove('hide');
        }
    }

    ajaxComplete() {
        super.ajaxComplete();
        this.removeLoader();

        // Enable submit button
        this.button.disabled = false;
    }

    resetFieldState(field) {
        // Remove has-error from field
        super.resetFieldState(field);
        if (field.$targetErrorClass) {
            field.$targetErrorClass.classList.remove(this.classes.error);
        }
    }
    setFieldState(field, error) {
        // Remove has-error to parent field (used for $targetErrorClass at line 22)
        super.setFieldState(field, error);
        if (field.$targetErrorClass) {
            field.$targetErrorClass.classList.add(this.classes.error);
        }
    }

    addLoader() {
        const lang = document.querySelector('html').getAttribute('lang');
        const loaderText = lang === 'fr' ? 'Chargement...' : lang === 'pt' ? 'Carregando...' : 'Loading...';

        const spinnerWrapper = document.createElement('div');
        spinnerWrapper.className = 'spinner-wrapper';
        const loader = document.createElement('span');
        loader.className = 'loader';
        loader.innerText = loaderText;

        spinnerWrapper.appendChild(loader);

        this.$el.appendChild(spinnerWrapper);
    }

    removeLoader() {
        this.$el.parentNode.querySelector('.spinner-wrapper')?.remove();
    }
}

Me.forms['ContactForm'] = ContactForm;
