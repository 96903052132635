import { ViewBasic } from '@quatrecentquatre/manage-me';

export class Header extends ViewBasic {
    constructor(options) {
        super(options);

        this.scrollPos = 0;
        this.headerContentEl = null;
        this.heroBackground = 'light';
    }

    initialize() {
        this.bindAll([
            'showSubmenu',
            'hideSubmenus',
            'toggleMobileMenu',
            'backToMainMobileMenu',
            'scroll',
            'changeImage',
        ]);
        this.headerContentEl = this.el.querySelector('.header-content');
        const heroClassList = this.el.nextElementSibling.classList;
        this.heroBackground = heroClassList.contains('dark') || heroClassList.contains('with-image') ? 'dark' : 'light';
        if (this.heroBackground === 'light') {
            this.headerContentEl.classList.add('light');
        } else {
            this.headerContentEl.classList.remove('dark');
        }

        this.addEvents();
    }

    addEvents() {
        window.addEventListener('scroll', this.scroll, { passive: true });

        const navLinks = this.el.querySelectorAll('.nav-link-has-submenu');
        navLinks.forEach((navLink) => {
            navLink.addEventListener('mouseenter', this.showSubmenu);
            navLink.addEventListener('click', this.showSubmenu);
        });

        const subLinks = this.el.querySelectorAll('.nav-submenu ul li');
        subLinks.forEach((subLink) => {
            subLink.addEventListener('mouseenter', this.changeImage);
            subLink.addEventListener('focus', this.changeImage);
        });

        this.el.addEventListener('mouseleave', this.hideSubmenus);

        this.el.querySelector('.burger-btn').addEventListener('click', this.toggleMobileMenu);

        const backButtons = this.el.querySelectorAll('.mobile-back-btn');
        backButtons.forEach((backButton) => {
            backButton.addEventListener('click', this.backToMainMobileMenu);
        });
    }

    removeEvents() {}

    showSubmenu(event) {
        if (
            (event.type === 'click' && window.innerWidth < 768) ||
            (event.type === 'mouseenter' && window.innerWidth >= 768)
        ) {
            this.hideSubmenus();
            this.headerContentEl.classList.add('is-opened');
            event.target.classList.add('active');
            event.target.nextElementSibling.classList.add('visible');

            // Get the image back to its default version
            const imageElement = event.target.nextElementSibling.querySelector('.column-image img');
            if (imageElement) {
                imageElement.src = imageElement.dataset.defaultImage;
            }
        }
    }

    hideSubmenus() {
        this.headerContentEl.classList.remove('is-opened');

        const navLinks = this.el.querySelectorAll('.nav-link-has-submenu.active');
        navLinks.forEach((navLink) => {
            navLink.classList.remove('active');
        });

        const navSubmenus = this.el.querySelectorAll('.nav-submenu.visible');
        navSubmenus.forEach((navSubmenu) => {
            navSubmenu.classList.remove('visible');
        });
    }

    toggleMobileMenu() {
        this.headerContentEl.classList.toggle('mobile-menu-visible');
        document.querySelector('html').classList.toggle('no-scroll');
    }

    backToMainMobileMenu(event) {
        event.target.parentElement.classList.remove('visible');
    }

    changeImage(event) {
        if (event.target.dataset.image) {
            const imageElement = event.target.closest('.nav-submenu').querySelector('.column-image img');
            if (imageElement) {
                imageElement.src = event.target.dataset.image;
            }
        }
    }

    scroll() {
        if (this.headerContentEl.classList.contains('mobile-menu-visible')) {
            return;
        }

        if (document.documentElement.scrollTop > 10) {
            this.headerContentEl.classList.remove('light');

            if (document.documentElement.scrollTop <= this.scrollPos) {
                if (this.headerContentEl.classList.contains('scrolled')) {
                    this.headerContentEl.classList.remove('scrolled');
                    window.dispatchEvent(new CustomEvent('header.unscrolled'));
                }
                this.headerContentEl.classList.add('dark');
            } else {
                this.headerContentEl.classList.add('scrolled');
                window.dispatchEvent(new CustomEvent('header.scrolled'));
            }
        } else {
            if (this.headerContentEl.classList.contains('scrolled')) {
                this.headerContentEl.classList.remove('scrolled', 'dark');
                window.dispatchEvent(new CustomEvent('header.unscrolled'));
            }

            if (this.heroBackground === 'light') {
                this.headerContentEl.classList.add('light');
            } else {
                this.headerContentEl.classList.remove('dark');
            }
        }

        this.scrollPos = document.documentElement.scrollTop;
    }
}

Me.views['Header'] = Header;
