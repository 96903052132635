import { ViewBasic } from '@quatrecentquatre/manage-me';

export class Inputs extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        this.bindAll(['changeHandler', 'focusHandler', 'blurHandler']);
        this.addEvents();
    }

    addEvents() {
        this.el.addEventListener('change', this.changeHandler);
        this.el.addEventListener('focus', this.focusHandler);
        this.el.addEventListener('blur', this.blurHandler);
    }

    removeEvents() {
        this.el.removeEventListener('change', this.changeHandler);
        this.el.removeEventListener('focus', this.focusHandler);
        this.el.removeEventListener('blur', this.blurHandler);
    }

    changeHandler(e) {
        if (this.el.value === '') {
            this.el.classList.remove('has-value');
        } else {
            this.el.classList.add('has-value');
            this.el.classList.remove('error');

            if (this.el.classList.contains('has-error')) {
                this.el.classList.remove('has-error');
                this.el.parentElement
                    .querySelector('.error-msg')
                    .classList.add('hide');
            }
        }
    }

    focusHandler(e) {
        this.el.classList.add('has-value');
        if (e.currentTarget.getAttribute('type') === 'date') {
            // if input is type date and has minimum date set for today
            if (
                this.el.getAttribute('min') === 'today' &&
                this.el.getAttribute('type') === 'date'
            ) {
                const today = new Date().toISOString().split('T')[0];
                this.el.setAttribute('min', today);
            }
            this.el.showPicker();
        }
    }

    blurHandler(e) {
        if (this.el.value === '') {
            this.el.classList.remove('has-value');
        }
    }
}

Me.views['Inputs'] = Inputs;
