import { ViewBasic } from '@quatrecentquatre/manage-me';
import gsap from 'gsap';

export class Timeline extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        this.bindAll(['nextSlide', 'previousSlide', 'resize', 'handleTouchStart', 'handleTouchMove', 'handleTouchEnd']);

        this.startX = 0;
        this.startY = 0;
        this.timeline = this.el.querySelector('.timeline-content');
        this.index = 1;
        this.slideCount = this.el.querySelectorAll('.dates .date').length;

        // Detect if its a touch device
        this.isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints;

        this.addEvents();

        this.updateSlideshowStatus();
    }

    addEvents() {
        this.el.querySelector('.next').addEventListener('click', this.nextSlide);
        this.el.querySelector('.previous').addEventListener('click', this.previousSlide);

        if (this.isTouchDevice) {
            this.timeline.addEventListener('touchstart', this.handleTouchStart);
            this.timeline.addEventListener('touchmove', this.handleTouchMove);
            this.timeline.addEventListener('touchend', this.handleTouchEnd);
        }

        window.addEventListener('resize', this.resize);
    }

    removeEvents() {
        this.el.querySelector('.next').removeEventListener('click', this.nextSlide);
        this.el.querySelector('.previous').removeEventListener('click', this.previousSlide);

        if (this.isTouchDevice) {
            this.timeline.removeEventListener('touchstart', this.handleTouchStart);
            this.timeline.removeEventListener('touchmove', this.handleTouchMove);
            this.timeline.removeEventListener('touchend', this.handleTouchEnd);
        }

        window.removeEventListener('resize', this.resize);
    }

    resize() {
        gsap.to(this.el.querySelectorAll('.details'), {
            height: this.el.querySelectorAll('.detail')[this.index - 1].offsetHeight,
            duration: 0.4,
            delay: 0.1,
        });
    }

    /**
     *  Handles the start of a touch event.
     *  This event is only triggered on touch devices
     * @param e
     */

    handleTouchStart(e) {
        const touch = e.touches[0];
        this.startX = touch.clientX;
        this.startY = touch.clientY;
        this.swiping = false; // Initial assumption
    }

    /**
     * Handles the move of a touch event and determines swipe direction.
     * @param e
     */

    handleTouchMove(e) {
        if (!this.swiping) {
            const touch = e.touches[0];
            const deltaX = Math.abs(touch.clientX - this.startX);
            const deltaY = Math.abs(touch.clientY - this.startY);

            // Check if the swipe is on X or Y axis
            if (deltaX > deltaY && deltaX > 10) {
                // Horizontal swipe detected
                this.swiping = 'horizontal';
                e.preventDefault();
            } else if (deltaY > deltaX && deltaY > 10) {
                // Vertical swipe detected allows for vertical scrolling
                this.swiping = 'vertical';
            }
        }
    }

    /**
     * Handles the end of a touch event and triggers slide change if appropriate.
     * @param e
     */

    handleTouchEnd(e) {
        if (this.swiping === 'horizontal') {
            const touch = e.changedTouches[0];
            const endX = touch.clientX;
            const diffX = this.startX - endX;

            // Swipe threshold
            if (Math.abs(diffX) > 30) {
                if (diffX > 0 && this.index < this.slideCount) {
                    this.nextSlide(e); // Swiped left and not at the last slide
                } else if (diffX < 0 && this.index > 1) {
                    this.previousSlide(e); // Swiped right and not at the first slide
                }
            }
        }
        // Reset the swiping status
        this.swiping = false;
    }

    /**
     * Increase index on next button
     * @param e
     */
    nextSlide(e) {
        this.index++;
        this.updateSlideshowStatus();
    }

    /**
     * Decrease index on previous button
     * @param e
     */
    previousSlide() {
        this.index--;
        this.updateSlideshowStatus();
    }

    /**
     * Update Slideshow status (progress / total slide) and animate the new active slides in.
     * @param e
     */
    updateSlideshowStatus() {
        let scope = this;

        this.el.querySelector('.next').disabled = false;
        this.el.querySelector('.previous').disabled = false;

        if (this.index === this.slideCount) {
            this.el.querySelector('.next').disabled = true;
        } else if (this.index === 1) {
            this.el.querySelector('.previous').disabled = true;
        }

        //reset active state
        this.el.querySelectorAll('.dates .date').forEach(function (element, index) {
            element.classList.remove('text-highlight');
            element.classList.remove('hidden');

            if (index < scope.index - 1) {
                element.classList.add('text-highlight');
                element.classList.add('hidden');
            }
        });

        this.el.querySelectorAll('.images img').forEach(function (element, index) {
            element.classList.remove('active');
        });

        this.el.querySelectorAll('.detail').forEach(function (element, index) {
            element.classList.remove('active');
            gsap.to(element, { opacity: '0', duration: 0.2 });
        });

        gsap.to(this.el.querySelector('.dates'), {
            x: `-${this.el.querySelectorAll('.dates .date')[this.index - 1].offsetLeft}px`,
            duration: 0.6,
            ease: 'Power1.easeOut',
        });

        this.el.querySelectorAll('.dates .date')[this.index - 1].classList.add('text-highlight');
        this.el.querySelectorAll('.images img')[this.index - 1].classList.add('active');
        this.el.querySelectorAll('.detail')[this.index - 1].classList.add('active');
        gsap.to(this.el.querySelectorAll('.detail')[this.index - 1], { opacity: '1', duration: 0.25 });
        gsap.to(this.el.querySelectorAll('.details'), {
            height: this.el.querySelectorAll('.detail')[this.index - 1].offsetHeight,
            duration: 0.4,
            delay: 0.1,
        });
    }
}

Me.views['Timeline'] = Timeline;
