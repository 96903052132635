import { ViewBasic } from '@quatrecentquatre/manage-me';

export class TechSpecsUnits extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        this.bindAll(['changeUnit']);

        this.unitButtons = this.el.querySelectorAll('.units-buttons button');
        this.unitCells = this.el.querySelectorAll('td.unit');

        this.addEvents();
    }

    addEvents() {
        if (this.unitButtons.length) {
            this.unitButtons.forEach((button) => {
                button.addEventListener('click', this.changeUnit);
            });
        }
    }

    removeEvents() {}

    changeUnit() {
        this.unitButtons.forEach((button) => {
            button.classList.toggle('active');
        });
        this.unitCells.forEach((cell) => {
            cell.classList.toggle('hidden');
        });
    }
}

Me.views['TechSpecsUnits'] = TechSpecsUnits;
