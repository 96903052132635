import { ViewBasic } from '@quatrecentquatre/manage-me';

export class FileInput extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        this.bindAll(['changeHandler', 'focusHandler', 'blurHandler', 'cancelUpload', 'buttonHandler']);
        this.wrapper = this.el.parentElement.parentElement;
        this.uploadButton = this.wrapper.querySelector('button[id^=filebtn-]');
        this.uploadButtonDefaultLabel = this.uploadButton.innerText;
        this.files = [];
        this.addEvents();
    }

    addEvents() {
        this.el.addEventListener('change', this.changeHandler);
        this.el.addEventListener('focus', this.focusHandler);
        this.el.addEventListener('blur', this.blurHandler);
        this.uploadButton.addEventListener('click', this.buttonHandler);
        // event to remove the file added to the upload field - need to fix bug
        this.el.parentElement.querySelector('button.remove').addEventListener('click', this.cancelUpload);
    }

    removeEvents() {
        this.el.removeEventListener('change', this.changeHandler);
        this.el.removeEventListener('focus', this.focusHandler);
        this.el.removeEventListener('blur', this.blurHandler);
    }

    buttonHandler() {
        this.wrapper.querySelector('input').click();
    }

    changeHandler(e) {
        const fileInput = e.target;
        const fileNameInput = this.el.parentElement.querySelector('.file-name');

        // Get all files and append names
        for (const key in fileInput.files) {
            if (Object.hasOwnProperty.call(fileInput.files, key)) {
                const fileObj = fileInput.files[key];
                const file = URL.createObjectURL(fileObj);
                const name = fileObj.name;
                this.files.push(file);

                const prepend = fileNameInput.textContent !== '' ? fileNameInput.textContent + ', ' : '';
                fileNameInput.textContent = prepend + name;
            }
        }

        // if value is empty or upload file is undefined (happens when upload is canceled)
        // empty last fileNameInput
        if (this.el.value === '' || typeof fileInput.files[0] === 'undefined') {
            fileNameInput.textContent = '';
            this.el.classList.remove('has-value');
            this.uploadButton.querySelector('span').innerText = this.uploadButtonDefaultLabel;
            this.el.parentElement.querySelector('button.remove').classList.add('hide');
        } else {
            this.el.classList.add('has-value');
            this.el.classList.remove('error');

            // if file is uploaded, remove error message and class.
            if (this.wrapper.classList.contains('has-error')) {
                this.wrapper.classList.remove('has-error');
                this.el.classList.remove('has-error');
                this.wrapper.querySelector('.error-msg').classList.add('hide');
            }

            if (this.files.length > 0) {
                this.uploadButton.querySelector('span').innerText = this.wrapper.parentElement.dataset.addAnotherLabel;
            } else this.uploadButton.querySelector('span').innerText = this.uploadButtonDefaultLabel;

            this.el.parentElement.querySelector('button.remove').classList.remove('hide');
        }
    }

    focusHandler(e) {
        // add delay before showing value
        setTimeout(() => {
            this.el.classList.add('has-value');
        }, 3500);
    }

    blurHandler(e) {
        if (this.el.value === '') {
            this.el.classList.remove('has-value');
            this.uploadButton.querySelector('span').innerText = this.uploadButtonDefaultLabel;
        }
    }

    cancelUpload() {
        const fileNameInput = this.el.parentElement.querySelector('.file-name');
        this.el.value = ''; // Reset the file input to clear the selection
        fileNameInput.textContent = '';
        this.el.classList.remove('has-value');
        this.el.classList.remove('error');
        this.el.parentElement.querySelector('button.remove').classList.add('hide');
        this.uploadButton.querySelector('span').innerText = this.uploadButtonDefaultLabel;

        this.files = [];

        // if file is removed, remove error message and class.
        if (this.wrapper.classList.contains('has-error')) {
            this.wrapper.classList.remove('has-error');
            this.el.classList.remove('has-error');
            this.wrapper.querySelector('.error-msg').classList.add('hide');
        }
    }
}

Me.views['FileInput'] = FileInput;
