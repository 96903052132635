import { ViewBasic } from '@quatrecentquatre/manage-me';

export class CategoryStickyListing extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        this.bindAll(['addScrollClass', 'removeScrollClass', 'showActiveLink']);
        this.addEvents();

        this.showActiveLink();

        const observer = new IntersectionObserver(([e]) => e.target.toggleAttribute('stuck', e.intersectionRatio < 1), {
            threshold: [1],
        });

        observer.observe(this.el);

        const hero = document.querySelector('section.hero');
        if (hero) {
            const heroObserver = new IntersectionObserver(
                ([e]) =>
                    this.el.classList.toggle('stuck', e.intersectionRect.height == 0 || e.intersectionRatio < 0.05),
                {
                    threshold: [0, 0.05, 0.1],
                },
            );

            heroObserver.observe(hero);
        }
    }

    addEvents() {
        window.addEventListener('header.scrolled', this.addScrollClass);
        window.addEventListener('header.unscrolled', this.removeScrollClass);
    }

    removeEvents() {}

    addScrollClass() {
        this.el.classList.add('scrolled');
    }
    removeScrollClass() {
        this.el.classList.remove('scrolled');
    }

    showActiveLink() {
        const active = this.el.querySelector('.active');
        if (active) {
            const list = this.el.querySelector('ul');
            const { left, right } = active.getBoundingClientRect();

            if (right > window.innerWidth) {
                list.scroll({
                    left,
                });
            }
        }
    }
}

Me.views['CategoryStickyListing'] = CategoryStickyListing;
