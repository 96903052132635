import { ViewBasic } from '@quatrecentquatre/manage-me';

export class CheckCompatibility extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        this.bindAll(['openOverlay']);
        this.addEvents();
    }

    addEvents() {
        this.el.addEventListener('click', this.openOverlay);
    }

    removeEvents() {
        this.el.removeEventListener('click', this.openOverlay);
    }

    openOverlay() {
        window.dispatchEvent(
            new CustomEvent('Overlay.open', {
                detail: {
                    id: 'overlay-check-compatibility',
                },
            }),
        );
    }
}

Me.views['CheckCompatibility'] = CheckCompatibility;
