window.SETTINGS = {
    BASE_URL: window.location.protocol + "//" + window.location.host + "/",
    CURRENT_URL: window.location.protocol + "//" + window.location.host + window.location.pathname,
    VERSIONNING: new Date().getTime(),
    BREAKPOINT: null,
    BREAKPOINTS: {
        LG_MIN: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--lg-min')),
        MD_MAX: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--md-max')),
        MD_MIN: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--md-min')),
        SM_MAX: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sm-max')),
        SM_MIN: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sm-min')),
        XS_MAX: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--xs-max'))
    }
};

if (process.env.RECAPTCHA_SITE_KEY) {
    window.SETTINGS.RECAPTCHA_SITE_KEY = process.env.RECAPTCHA_SITE_KEY
}
